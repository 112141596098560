import React, { useState, useEffect, useRef } from "react";

function Hero() {
 
  return (
    <>
     
      <section id="hero" class="hero">
        <div class="background hero__background">
          <div class="background__text">
            <span>Excellence</span>
          </div>
        </div>
        <div class="hero__content">
          <p class="hero__title" >
            A Legacy of Diversified Excellence
          </p>
          <p class="hero__subtitle">
            JDB Group stands as a versatile conglomerate actively engaged in a
            broad spectrum of manufacturing industries, hospitality, service
            sectors, and real estate.
          </p>
        </div>
      </section>
    </>
  );
}

export default Hero;
