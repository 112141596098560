import React from "react";

function ImgSlider() {
  return (
    <>
      <div></div>
    </>
  );
}

export default ImgSlider;
