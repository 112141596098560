import React, { useState } from "react";
import cta from "../Components/assets/img/careercover.jpg";
import cvector from "../Components/assets/img/careervector.jpg";
import GoToTop from "../GoToTop";
import { Slide, Fade } from "react-awesome-reveal";
import ApplyImg from "../Components/assets/img/jobapply.jpg";
import { Link, useNavigate } from "react-router-dom";
function Career() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/apply_now");
  };
  return (
    <>
      <GoToTop />
      <div
        style={{
          background: `url(${cta}) no-repeat center center`,
          backgroundSize: "cover",
          width: "100%",
          height: "40vh",
          marginTop: "20px",
        }}
      >
        <div
          className="container text-center"
          style={{ color: "white", padding: "90px 10px" }}
        >
          <h2 style={{ fontWeight: "900", marginTop: "50px" }}>
            Careers​ in JDB Group
          </h2>
          <p style={{ color: "white" }}>
            Join JDB Group for a dynamic career where innovation, growth, and
            success thrive together.
          </p>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6">
            <h4>Careers</h4>
            <p style={{ textAlign: "justify" }}>
              At JDB Group, we are excited about the prospect of you joining our
              dynamic and diverse team. Our organization boasts a workforce of
              over a thousand experts, who bring their talents and expertise to
              both domestic and international markets. We pride ourselves on
              being a global player with a strong commitment to excellence,
              innovation, and integrity. Our team members are the cornerstone of
              our success, and we deeply value the unique skills, perspectives,
              and experiences they bring to the table. By joining JDB Group, you
              will be part of an organization that is dedicated to fostering an
              inclusive and collaborative work environment where everyone has
              the opportunity to thrive and contribute meaningfully to our
              collective achievements.
              <br />
              <br />
              We understand that our greatest asset is our people, and we go to
              great lengths to ensure that JDB Group is a place where you can
              grow both personally and professionally. Our commitment to our
              employees is reflected in our efforts to create a supportive and
              rewarding work environment. We invest in continuous learning and
              development programs, encourage open communication, and promote a
              culture of recognition and appreciation. At JDB Group, you will
              find ample opportunities to advance your career, take on exciting
              challenges, and make a real impact. We believe that when our
              employees succeed, our organization succeeds, and together we can
              continue to reach new heights and set new standards in our
              industry. We look forward to the unique contributions you can
              bring to our team as we work together towards a brighter future.
            </p>
          </div>
          <div className="col-lg-6">
            <img
              style={{ width: "100%", mixBlendMode: "multiply" }}
              src={cvector}
              alt="career-in-jdb group"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <Link to="/apply_now">
              <img
                style={{ width: "100%" }}
                src={ApplyImg}
                alt="career-in-jdb group"
              />
            </Link>
          </div>
        </div>

        <div className="section-title" style={{ marginTop: "45px" }}>
          <h1
            className="mb-4 heading textprimary text-center"
            style={{ fontWeight: "700" }}
          >
            <Fade cascade damping={0.1}>
              Why Join Us?
            </Fade>
          </h1>

          <p className="text-center">
            At JDB Group, our core values are founded on trust, valuing
            relationships, driving innovation, and a dedication to continuous
            learning and growth. We ensure accountability, equality, innovation,
            and excellence in every aspect of our work.
          </p>
        </div>

        <div className="row my-3">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  <i
                    style={{ fontSize: "25px", color: "cornflowerblue" }}
                    class="fa fa-globe"
                    aria-hidden="true"
                  ></i>{" "}
                  Global Expertise
                </h4>
                <p>
                  Be part of a team with over a thousand experts operating
                  domestically and internationally, offering a diverse and
                  enriching work environment.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  <i
                    style={{ fontSize: "25px", color: "cornflowerblue" }}
                    class="fa fa-line-chart"
                    aria-hidden="true"
                  ></i>{" "}
                  Career Development
                </h4>
                <p>
                  Benefit from continuous learning and development programs that
                  support your personal and professional growth.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  <i
                    style={{ fontSize: "25px", color: "cornflowerblue" }}
                    class="fa fa-pie-chart"
                    aria-hidden="true"
                  ></i>{" "}
                  Innovative Culture
                </h4>
                <p>
                  Work in an environment that fosters innovation, encouraging
                  new ideas and creative solutions.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  <i
                    style={{ fontSize: "25px", color: "cornflowerblue" }}
                    class="fa fa-life-ring"
                    aria-hidden="true"
                  ></i>{" "}
                  Supportive Environment
                </h4>
                <p>
                  Thrive in a workplace that values trust, relationships, and
                  collaboration, ensuring a rewarding and inclusive atmosphere
                  for all.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  <i
                    style={{ fontSize: "25px", color: "cornflowerblue" }}
                    class="fa fa-user"
                    aria-hidden="true"
                  ></i>{" "}
                  Employee Recognition
                </h4>
                <p>
                  Experience a culture that acknowledges and rewards your
                  contributions, making you feel valued and appreciated.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  <i
                    style={{ fontSize: "25px", color: "cornflowerblue" }}
                    class="fa fa-code-fork"
                    aria-hidden="true"
                  ></i>{" "}
                  Work-Life Balance
                </h4>
                <p>
                  Enjoy policies and practices that promote a healthy work-life
                  balance, ensuring your well-being and satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          position: "sticky",
          background: "rgb(0,0,0)",
          background:
            "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(63,63,63,1) 100%)",
          bottom: "0px",
          height: "20vh",
        }}
      >
        <div className="container">
          <div
            onClick={handleClick}
            className="row text-left"
            style={{ color: "white", padding: "30px 10px" }}
          >
            <div className="col-md-10">
              <h2 style={{ fontWeight: "900" }}>Want to Join JDB Group?</h2>
              <p style={{ color: "white" }}>
                Join our esteemed team and contribute to our continued success!
              </p>
            </div>
            <div className="col-md-2">
              {" "}
              <Link className="btn btn-primary" to="/apply_now">
                Apply Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
