import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import cover_img from "../../Components/assets/img/novotel_cover_page.webp";
import cover_imgmb from "../../Components/assets/img/novotel_cover_mb.webp";
import img1 from "../../Components/assets/img/Img/Novotel/3.webp";
import img2 from "../../Components/assets/img/Img/Novotel/2.webp";
import img3 from "../../Components/assets/img/Img/Novotel/4.webp";
import img4 from "../../Components/assets/img/Img/Novotel/5.webp";
import GoToTop from "../../GoToTop";

function Novotel() {
  return (
    <>
      <GoToTop />
      <img
        src={cover_img}
        id="pccover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <img
        src={cover_imgmb}
        id="mbcover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <div class="container-xxl aboutus my-3" >
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              {/* <h6 class="section-title text-start textprimary text-uppercase">
                <Fade cascade damping={0.1}>
                  About Us
                </Fade>
              </h6> */}
              <Slide>
                <h1 className="mb-4 heading textprimary">
                  Accor Hotels - Novotel Guwahati
                </h1>
                <p>SM JDB ESTATE PVT LTD</p>
              </Slide>
              <Fade cascade damping={1}>
                <p class="mb-4">
                  Featuring a host of 5-star amenities, "Novotel Guwahati" sets
                  the gateway of European hospitality to North East India. The
                  hotel presents 118 sophisticatedly designed rooms and suites,
                  4 meeting spaces with modern and luxurious amenities that can
                  accommodate up to 250 guests. Being strategically placed near
                  the commercial hub, the hotel offers an ideal location in the
                  heart of Guwahati - Shillong road. The hotel is the newest
                  5-star offering to the city and is the most sought out and
                  preferred destinations for tourists and corporates who come to
                  the Gateway of the North-Eastern India - Guwahati.
                </p>
              </Fade>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={img1}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={img2}
                  />
                </div>
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-70 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={img3}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={img4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
    </>
  );
}

export default Novotel;
