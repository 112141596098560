import "./App.css";
import Header from "./Components/Header";
import Header2 from "./Components/Header2";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import { Routes, Route } from "react-router-dom";
import About from "./Components/About";
import RakshaCement from "./Components/Brands/RakshaCement";
import Novotel from "./Components/Brands/Novotel";
import Login from "./Components/Admin/Login";
import FoodProccessing from "./Components/Brands/FoodProccessing";
import AadharInd from "./Components/Brands/AadharInd";
import SteelIndustry from "./Components/Brands/SteelIndustry";
import Team from "./Components/Team";
import CSRactivities from "./Components/CSRactivities";
import JobApply from "./Components/JobApply";

import ProtectedRoute from "./Components/Admin/context/ProtectedRoute";
import { UserAuthContextProvider } from "./Components/Admin/context/UserAuthContext";
import Dashboard from "./Components/Admin/Dashboard";
import Career from "./Components/Career";
import Page404 from "./Components/Page404";

function App() {
  return (
    <div className="content">
      <Routes>
        <Route
          path="*"
          element={
            <>
              <Header />
              <Page404 />
              <Footer />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <Header />
              <Home />

              <Footer />
            </>
          }
        />

        <Route
          path="/contact"
          element={
            <>
              <Header2 />
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.119382715906!2d91.7690505748693!3d26.160241192023392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a596d6368d34d%3A0x8baf797539fb5cfa!2sGaurab%20Bh%C3%A0wan!5e0!3m2!1sen!2sin!4v1711652831813!5m2!1sen!2sin"
                  width="100%"
                  height="350"
                  style={{ border: "0", marginTop: "10vh" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <Contact /> <Footer />
            </>
          }
        />

        <Route
          path="/about"
          element={
            <>
              <Header2 />
              <About /> <Footer />
            </>
          }
        />

        <Route
          path="/team"
          element={
            <>
              <Header2 />
              <Team /> <Footer />
            </>
          }
        />

        <Route
          path="/career"
          element={
            <>
              <Header2 />
              <Career /> <Footer />
            </>
          }
        />

        <Route
          path="/apply_now"
          element={
            <>
              <Header2 />
              <JobApply /> <Footer />
            </>
          }
        />

        <Route
          path="/csr_activities"
          element={
            <>
              <Header2 />
              <CSRactivities /> <Footer />
            </>
          }
        />

        <Route
          path="/raksha_cement"
          element={
            <>
              <Header2 />
              <RakshaCement /> <Footer />
            </>
          }
        />

        <Route
          path="/raksha"
          element={
            <>
              <Header2 />
              <RakshaCement /> <Footer />
            </>
          }
        />

        <Route
          path="/food_proccessing"
          element={
            <>
              <Header2 />
              <FoodProccessing /> <Footer />
            </>
          }
        />

        <Route
          path="/aadhar_industries"
          element={
            <>
              <Header2 />
              <AadharInd /> <Footer />
            </>
          }
        />

        <Route
          path="/novotel"
          element={
            <>
              <Header2 />
              <Novotel /> <Footer />
            </>
          }
        />

        <Route
          path="/cement_manufacturing"
          element={
            <>
              <Header2 />
              <RakshaCement /> <AadharInd /> <Footer />
            </>
          }
        />

        <Route
          path="/hëRRlích_Alûmin"
          element={
            <>
              <Header2 />
              <SteelIndustry /> <Footer />
            </>
          }
        />
      </Routes>

      <UserAuthContextProvider>
        <Routes>
          <Route
            path="/authentication"
            element={
              <>
                <Login />
              </>
            }
          />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
