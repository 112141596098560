import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import img4 from "../Components/assets/img/Img/other/1.webp";
import img1 from "../Components/assets/img/Img/Novotel/cover_img.webp";
import img2 from "../Components/assets/img/Img/Novotel/2.webp";
import img3 from "../Components/assets/img/Img/other/3.webp";
import aboutbanner from "../Components/assets/img/aboutusbanner.webp";
import aboutbannermb from "../Components/assets/img/aboutusmb.webp";
import GoToTop from "../GoToTop";
import Chart from "./Chart";
import BarChart from "./BarChart";
import { Table } from "react-bootstrap";
function About() {
  return (
    <>
      <GoToTop />
      <img
        src={aboutbanner}
        id="pccover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <img
        src={aboutbannermb}
        id="mbcover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <div class="container-xxl aboutus my-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <Slide>
                <h1 className="mb-4 heading textprimary">Who we are</h1>
              </Slide>
              <Fade cascade damping={1}>
                <p class="mb-4">
                  JDB Group, founded in 1990, has evolved into a dynamic
                  conglomerate with a rich history of growth and
                  diversification. Initially rooted in the supply of coal,
                  bamboo, and more, the group transitioned towards
                  industrialization, marking a significant milestone with the
                  establishment of 'GG Lime Industry' in 2003.
                </p>

                <p>
                  Today, as a diversified Organization, JDB GROUP is active in a
                  wide range of industries including manufacturing of LAM-Coke,
                  Lime products, Food Products like wheat flour and snack
                  products, Cement, Plywood etc, apart from its footprint in
                  service sector industries like state-of-the-art integrated
                  cold chain / Cold storage unit and a premium 5-star hotel.
                  They have various trading units and real estate developers as
                  well. With about fourteen (14) firms and companies under the
                  spacious umbrella of JDB GROUP, the GROUP has evolved as a
                  multi-dimensional and diversified business entity in the North
                  Eastern India. The real strength of JDB GROUP comes from their
                  commitment to the society. They believe in growing their
                  business while enhancing the lives of people, everywhere they
                  operate.
                </p>
              </Fade>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={img1}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={img2}
                  />
                </div>
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-70 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={img3}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-90 wow zoomIn"
                    data-wow-delay="0.7s"
                    style={{ marginTop: "-4vh" }}
                    src={img4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="my-5">
            <Fade cascade damping={0.5}>
              <p>
                The GROUP is reputed employment providers in this region and has
                wide range and categories of people across the Organization. In
                addition to business, JDB GROUP has also invested substantially
                in ventures of social significance. Their success is founded on
                a very simple philosophy: - 'they honor their commitment to all
                their stakeholders and make a significant contribution to the
                communities and areas that they serve'. The GROUP wants to
                change people's lives for the better by providing them
                opportunities and by creating an environment that drives
                efficiency and productivity.
              </p>
              <p>
                Amid their wide range of business entities, they are dedicated
                to delighting their stakeholders and "creating memorable
                moments" for their customers. Wherever they operate, they strive
                to ensure that JDB GROUP takes a balanced approach to all
                customers, adding value through managerial capabilities and
                local knowledge, as well as taking significant care to consider
                the community, people and the surrounding environment through a
                wide range of corporate and socially responsible initiatives.
              </p>
              <p>
                JDB GROUP has been a family business with a deep-rooted
                pioneering spirit and a wealth of highly relevant experience.
                Their goal is to remain at the cutting edge of each Firm so that
                they may continue to create value for their shareholders,
                surrounding communities and the wider economy. The GROUP is
                proud of their past and passionate about their future and looks
                forward to continuing their success alongside each of their
                partners.
              </p>
            </Fade>
          </div>
          <div class="row">
            <div className="col-lg-4">
              <div class="card2 my-2">
                <h4>Our Mission</h4>
                <p>
                  We aspire to play a significant role in the economic
                  development of Assam and North Eastern State India and
                  contributing towards making Northeast India a Trading and
                  manufacturing hub
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div class="card2 my-2">
                <h4>Our Values</h4>
                <p>
                  Integrity, Commitment & Passion. Employees First - If you take
                  care of your employees they will take care of your customers
                  and your business will take care of itself.
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div class="card2 my-2">
                <h4>Our Vision</h4>
                <p>
                  Our vision is to become one of the leading trusted business
                  houses in the country and beyond.
                </p>
              </div>
            </div>
          </div>
          <br />
          <br /> <br />
          <Chart /> <br /> <br />
          <h1 className="mb-4 heading textprimary text-center">
            JDB Group Growth & Future Growth Projection
          </h1>
          <div className="row my-5">
            <div className="col-lg-8">
              <BarChart />
            </div>
            <div className="col-lg-4">
              <Table
                responsive
                striped
                bordered
                size="sm"
                style={{ marginTop: "4.5vh" }}
              >
                <thead
                  style={{
                    background: "#1d9c09",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
                  <tr>
                    <th>Year</th>
                    <th>Turn Over</th>
                    <th style={{ width: "20vh" }}>Employee Strength (No.s)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2014-15</td>
                    <th>313 Cr</th>
                    <td>1200</td>
                  </tr>

                  <tr>
                    <td>2017-18</td>
                    <th>360 Cr</th>
                    <td>1700</td>
                  </tr>

                  <tr>
                    <td>2021-22</td>
                    <th>550 Cr</th>
                    <td>2200</td>
                  </tr>

                  <tr>
                    <td>2024-25</td>
                    <th>1000 Cr</th>
                    <td>3500</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
    </>
  );
}

export default About;
