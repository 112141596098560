import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./assets/img/Logos/mainLogo.webp";
import { Slide, Fade } from "react-awesome-reveal";
function Header() {
  const [showDiv, setShowDiv] = useState(false);

  const handleButtonClick = () => {
    setShowDiv(!showDiv); // Toggles the value of showDiv
  };

  return (
    <>
      <header
        id="header2"
        className="content fixed-top d-flex align-items-center"
      >
        <div className="container d-flex align-items-center">
          {/* <h1 className="logo me-auto">
            <Link to="/">
              JDB Group<span>.</span>
            </Link>
          </h1> */}
          {/* <!-- Uncomment below if you prefer to use an image logo --> */}
          <Link to="/" className="logo me-auto">
            <img src={Logo} alt="" />
          </Link>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>

              <li className="dropdown">
                <a href="">
                  <span>Ventures</span> <i className="bi bi-chevron-down"></i>
                </a>
                <ul>
                  <li className="dropdown">
                    <a href="#">
                      <span>Manufacturing Industries</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Lime Products</a>
                      </li>
                      <li>
                        <Link to="/food_proccessing">Food Processing</Link>
                      </li>
                      <li>
                        <Link to="/cement_manufacturing">
                          Cement Manufacturing
                        </Link>
                      </li>
                      <li>
                        <Link to="/hëRRlích_Alûmin">Aluminium Profile</Link>
                      </li>
                      <li>
                        <a href="#">Ethanol Production</a>
                      </li>
                      <li>
                        <a href="#">Wheat Flour Production</a>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown">
                    <a href="#">
                      <span>Hospitality</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </a>
                    <ul>
                      <li>
                        <Link to="/novotel">Novotel Guwahati</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown">
                    <a href="#">
                      <span>Service Industries</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </a>
                    <ul>
                      <a href="#">Marketing and Distribution</a>
                      <a
                        href="#"
                        style={{ marginLeft: "3vh", color: "#424242" }}
                      >
                        Pushpanjali Entrade Pvt Ltd
                      </a>
                      <a
                        href="#"
                        style={{ marginLeft: "3vh", color: "#424242" }}
                      >
                        Global Entrade
                      </a>

                      <a href="#">
                        <span>Construction & Real Estate</span>
                      </a>
                      <a
                        href="#"
                        style={{ marginLeft: "3vh", color: "#424242" }}
                      >
                        Nowrangroy Metals Pvt Limited
                      </a>
                      <a
                        href="#"
                        style={{ marginLeft: "3vh", color: "#424242" }}
                      >
                        Ganapati Developers
                      </a>
                      <a
                        href="#"
                        style={{ marginLeft: "3vh", color: "#424242" }}
                      >
                        Aastha Prestige
                      </a>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <Link className="nav-link" to="/about">
                  Explore Us
                </Link>
              </li>

              <li>
                <Link className="nav-link scrollto" to="/team">
                  Team
                </Link>
              </li>

              <li>
                <Link className="nav-link" to="/csr_activities">
                  CSR Activities
                </Link>
              </li>

              <li>
                <Link className="nav-link scrollto" to="/career">
                  Career
                </Link>
              </li>

              <li>
                <Link className="nav-link scrollto" to="/contact">
                  Contact
                </Link>
              </li>

              <li>
                <Link className="nav-link scrollto" to="/authentication">
                  <i
                    class="fa fa-user"
                    style={{ margin: "-1.5px 5px 0 0" }}
                  ></i>{" "}
                  Login
                </Link>
              </li>
            </ul>

            <div onClick={handleButtonClick}>
              {showDiv ? (
                <i
                  style={{ color: "grey" }}
                  className="bfa fa-times mobile-nav-toggle"
                ></i>
              ) : (
                <i
                  style={{ color: "white" }}
                  className="fa fa-bars mobile-nav-toggle"
                ></i>
              )}
            </div>
          </nav>

          {/* <a href="" className="get-started-btn scrollto">
            <i class="fa fa-user"></i> Login
          </a> */}
        </div>
      </header>
      {showDiv && (
        <div
          style={{
            backgroundColor: "#121212",
            width: "100%",
            height: "37vh",
            position: "fixed",
            zIndex: "100",
            color: "white",
            left: "0px",
            top: "10vh",
            padding: "5vh 10px 10px 20px",
          }}
        >
          <Slide>
            <Link to="/" style={{ color: "white" }}>
              Home
            </Link>
            <br />
            <Link to="/about" style={{ color: "white" }}>
              About us
            </Link>{" "}
            <br />
            <Link to="/career" style={{ color: "white" }}>
              Career
            </Link>{" "}
            <br />
            <Link to="/contact" style={{ color: "white" }}>
              Contact Us
            </Link>{" "}
            <br />
            <Link to="/authentication" style={{ color: "white" }}>
              Login
            </Link>{" "}
            <br />
          </Slide>
        </div>
      )}
    </>
  );
}

export default Header;
