import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";

const dataSource = {
  name: "Chairman & Managing Director (CMD)",
  className: "mainHeadChart",
  children: [
    {
      name: "Board Of Directors",
      //   title: "department manager",
      className: "middle-level",
    },
    {
      name: "Chief Executive Officer -Group",
      //   title: "department manager",
      className: "middle-level",
      children: [
        {
          name: "CFO -Group",

          className: "product-dept",
        },
        {
          name: "Head HR & Admin Group",

          className: "product-dept",
        },
        {
          name: "Head -Projects",

          className: "product-dept",
        },
        {
          name: "Head of Engineering",

          className: "product-dept",
        },
        {
          name: "Head -SCM",

          className: "product-dept",
        },
        {
          name: "Head IT & Technology",

          className: "product-dept",
        },
      ],
    },
    {
      name: "Management Representative",

      className: "middle-level",
    },
  ],
};

const cfoData = {
  name: "CFO -Group",
  className: "product-dept",
  children: [
    {
      name: "GM -Finance",
      //   title: "department manager",
      className: "rd-dept",
      children: [
        {
          name: "Manager Commercial",

          className: "frontend1",
        },
        {
          name: "Manager Accounts",

          className: "frontend1",
        },
      ],
    },
  ],
};

const hrData = {
  name: "Head HR & Admin Group",
  className: "product-dept",
  children: [
    {
      name: "HR Manager",
      //   title: "department manager",
      className: "rd-dept",
    },
    {
      name: "Manager Legal",
      //   title: "department manager",
      className: "rd-dept",
    },
    {
      name: "Manager CSD",
      //   title: "department manager",
      className: "rd-dept",
    },
  ],
};

const projectHData = {
  name: "Head Projects",
  className: "product-dept",
  children: [
    {
      name: "Project Manager",
      //   title: "department manager",
      className: "rd-dept",
    },
    {
      name: "Manager Planning",
      //   title: "department manager",
      className: "rd-dept",
    },
  ],
};

const enggHData = {
  name: "Head of Engineering",
  className: "product-dept",
  children: [
    {
      name: "Plant Incharge",

      className: "rd-dept",
      children: [
        {
          name: "Production Team",
          className: "frontend1",
        },
        {
          name: "Manager QC",
          className: "frontend1",
        },
      ],
    },
  ],
};
const scmHData = {
  name: "Head SCM",
  className: "product-dept",
  children: [
    {
      name: "Manager Store",

      className: "rd-dept",
    },
    {
      name: "Manager Purchase",

      className: "rd-dept",
    },
    {
      name: "Manager Logistics",

      className: "rd-dept",
    },
  ],
};

const ITData = {
  name: "Head IT & Technology",
  className: "product-dept",
  children: [
    {
      name: "System Administrator",

      className: "rd-dept",
    },
  ],
};

const MyNodeComponent = ({ node }) => {
  return (
    <div className={`initechNode ${node.className}`}>
      <div className="title">{node.name}</div>
      {/* <div className="fullname">{node.title}</div> */}
    </div>
  );
};

const Chart = () => {
  return (
    <>
      <h1 className="mb-4 heading textprimary text-center">
        Organizational Chart -JDB Group
      </h1>

      <div
        id="chart-container"
        style={{ overflowX: "scroll", marginTop: "50px" }}
      >
        <OrgChart tree={dataSource} NodeComponent={MyNodeComponent} />
      </div>

      <div className="row my-3">
        <div
          id="chart-container"
          className="col-lg-4 my-4"
          style={{ overflowX: "scroll" }}
        >
          <OrgChart tree={cfoData} NodeComponent={MyNodeComponent} />
        </div>
        <div
          id="chart-container"
          className="col-lg-4 my-4"
          style={{ overflowX: "scroll" }}
        >
          <OrgChart tree={hrData} NodeComponent={MyNodeComponent} />
        </div>
        <div
          id="chart-container"
          className="col-lg-4 my-4"
          style={{ overflowX: "scroll" }}
        >
          <OrgChart tree={projectHData} NodeComponent={MyNodeComponent} />
        </div>

        <div
          id="chart-container"
          className="col-lg-4 my-4"
          style={{ overflowX: "scroll" }}
        >
          <OrgChart tree={enggHData} NodeComponent={MyNodeComponent} />
        </div>

        <div
          id="chart-container"
          className="col-lg-5 my-4"
          style={{ overflowX: "scroll" }}
        >
          <OrgChart tree={scmHData} NodeComponent={MyNodeComponent} />
        </div>

        <div
          id="chart-container"
          className="col-lg-3 my-4"
          style={{ overflowX: "scroll" }}
        >
          <OrgChart tree={ITData} NodeComponent={MyNodeComponent} />
        </div>
      </div>

      {/* Bar graphs */}
    </>
  );
};

export default Chart;
