import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import img1 from "../assets/img/Img/other/Raksha1.jpg";
import img2 from "../assets/img/Img/other/Raksha2.jpg";
import img3 from "../assets/img/Img/other/Raksha3.jpg";
import img4 from "../assets/img/Img/other/Raksha4.jpg";
import raksha_cover from "../assets/img/raksha_cover_page.webp";
import raksha_covermb from "../assets/img/raksha_cover_mb.webp";
import GoToTop from "../../GoToTop";

function RakshaCement() {
  return (
    <>
      <GoToTop />
      <img
        src={raksha_cover}
        id="pccover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <img
        src={raksha_covermb}
        id="mbcover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <div class="container-xxl aboutus my-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <Slide>
                <h1 className="mb-4 heading textprimary">
                  Raksha Cements Private Limited- "Saalo Saal Mazbooti Ka Vada"
                </h1>
              </Slide>
              <Fade cascade damping={1}>
                <p class="mb-4">
                  It is our pleasure to introduce ourselves as a manufacturer of
                  Cement in the North Eastern Region. We are manufacturing
                  “RAKSHA” Cement from state of the art plant situated in
                  TEPESIA, SONAPUR, ASSAM, INDIA. We are one of the dominant
                  players in the region.
                  <br />
                  <br />
                  RAKSHA Cements Pvt. Ltd. is the North East’s foremost
                  manufacturer of 43- grade Ordinary Portland Cement & Portland
                  Pozzolana Cement with a North East wide network of factories
                  and marketing office. RAKSHA brand name is synonymous with
                  cement and enjoys a high level of equity in the North East
                  Market. Presently we are producing 60,000 M.T. Per Annum and
                  are planning to expand to a 1,90,000 M.T. Per Annum. Our plant
                  quality performance is of high standard which gives better
                  product and ensures faster setting which results to faster
                  construction.
                  <br />
                  <br />
                  Our fully equipped laboratory is as per BIS norms. We
                  constantly check the quality of cement under controlled
                  temperatures and the same is done by eminent chemists. Cement
                  samples are being regularly sent for testing at the National
                  Test House and other test houses for independent
                  evaluations of quality.
                </p>
              </Fade>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={img1}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={img2}
                  />
                </div>
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-70 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={img3}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={img4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
    </>
  );
}

export default RakshaCement;
