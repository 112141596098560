import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useUserAuth } from "./context/UserAuthContext";
import GoToTop from "../../GoToTop";
import { Alert } from "react-bootstrap";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      await logIn(email, password);
      setSuccessMsg("Credentials verified. Redirecting...");

      setTimeout(() => {
        setSuccessMsg("");
        setLoading(false);
        navigate("/dashboard", { replace: true });
      }, 4000);
    } catch (err) {
      setError("Alert! You are not authorized");
      setLoading(false);
      setTimeout(() => {
        setError("");
        setLoading(false);
      }, 3000);
    }
  };

  return (
    <>
      <GoToTop />
      <section class="MainContainer my-3">
        <section class="LoginContainer">
          <section class="CompanyContainer">
            <div class="CompanyLogoConatiner">
              {/* <img
                class="CompanyLogoImg"
                src="https://i.imgur.com/szneKCD.png"
              /> */}
            </div>
            <div class="CompanyName">JDB Group</div>
          </section>

          <form onSubmit={handleSubmit}>
            <section class="LoginHeaderContainer">
              <div class="LoginHeaderTitle">Log in</div>
              <div class="LoginHeaderSubTitle">
                Welcome to <b>JDB Group!</b> Please Enter your Details.
              </div>
              {error && <Alert variant="danger">{error}</Alert>}
              {successmsg && (
                <Alert
                  variant="success"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {successmsg}
                </Alert>
              )}
            </section>

            <section class="FormContainer">
              <div class="InputContainer">
                <label class="label">Email Address*</label>
                <input
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </div>
              <div class="InputContainer">
                <label class="label">Password*</label>
                <div class="PasswordInputContainer">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    type="password"
                  />
                </div>
                <label class="PasswordLabel">
                  Must be 8 characters at least
                </label>
              </div>
              <div class="InputContainer RememberForgotContainer">
                <div class="ForgotPasswordContainer">
                  <a>Forgot Password?</a>
                </div>
              </div>
            </section>

            <section class="ButtonsContainer">
              <div class="SignInButtonContainer">
                {loading === true ? (
                  <>
                    <button className="btn btn-primary" disabled id="go">
                      Loading...
                    </button>
                  </>
                ) : (
                  <>
                    <button type="submit" id="go" class="SigninButton">
                      Authenticate
                    </button>
                  </>
                )}
              </div>
            </section>
          </form>
        </section>
      </section>
    </>
  );
}

export default Login;
