import React, { useState } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import GoToTop from "../GoToTop";
import { db } from "../Firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Swal from "sweetalert2";
import emailjs from "emailjs-com"; // Import EmailJS library

function Contact() {
  const generateUniqueId = () => {
    return Math.floor(1000000 + Math.random() * 9000000).toString();
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uniqueId = generateUniqueId();

    try {
      const docRef = await addDoc(collection(db, "contacts"), {
        ...formData,
        createdAt: serverTimestamp(),
        uniqueId: uniqueId,
      });

      // Send email to the customer using EmailJS
      const serviceID = "service_16tftey"; // Replace with your EmailJS service ID
      const templateID = "template_raz39vd"; // Replace with your EmailJS template ID
      const userID = "tndxMLtZKtWq-UP5t"; // Replace with your EmailJS user ID

      // Prepare data to send to EmailJS template
      const emailParams = {
        to_email: formData.email,
        name: formData.name,
        subject: formData.subject,
        uniqueId: uniqueId,
      };

      // Send email using EmailJS
      emailjs
        .send(serviceID, templateID, emailParams, userID)
        .then((response) => {
          console.log("Email sent:", response.status, response.text);
        })
        .catch((error) => {
          console.error("Email sending failed:", error);
        });

      // Display success message to the user
      Swal.fire({
        title: "Thank you for getting in touch!",
        html: `We appreciate you contacting us. Your reference ID is <strong>#${uniqueId}</strong>. One of our team members will get back in touch with you soon!`,
        icon: "success",
      });

      // Reset the form after successful submission
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      console.error("Error adding document: ", error);
      Swal.fire({
        title: "Error!",
        html: `Something went wrong, please try again.`,
        icon: "error",
      });
    }
  };

  return (
    <>
      <GoToTop />
      <section id="contact" className="contact my-4">
        <div className="container">
          <div className="section-title">
            <h1
              className="mb-4 heading textprimary text-center"
              style={{ fontWeight: "700" }}
            >
              <Fade cascade damping={0.1}>
                Contact Us
              </Fade>
            </h1>

            <p className="text-center">
              Connect with us today! Whether you have questions, feedback, or
              inquiries, we're here to assist you. Reach out via phone, email,
              or our online form. Your message matters to us, and we're eager to
              hear from you. Let's chat and find solutions together!
            </p>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i style={{ color: "black" }} className="fa fa-map"></i>
                    <h3 style={{ color: "black" }}>Our Address</h3>
                    <p>
                      29(B), Gaurav Bhawan, 3rd Floor, D. Neog Path, G S Road,
                      ABC Guwahati, 781005
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i
                      style={{ color: "black" }}
                      className="fa fa-envelope"
                    ></i>
                    <h3 style={{ color: "black" }}>Email Us</h3>
                    <p>info@jdbgrp.com </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i style={{ color: "black" }} className="fa fa-phone"></i>
                    <h3 style={{ color: "black" }}>Call Us</h3>
                    <p>0361-2465182</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="col form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    placeholder="Subject"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="5"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br />
      </section>
    </>
  );
}

export default Contact;
