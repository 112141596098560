import React from "react";

import img4 from "../Components/assets/img/Img/other/1.webp";
import img1 from "../Components/assets/img/Img/Novotel/cover_img.webp";
import img2 from "../Components/assets/img/Img/Novotel/2.webp";
import img3 from "../Components/assets/img/Img/other/3.webp";
import { Slide, Fade } from "react-awesome-reveal";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import Hero from "./Hero";
import Brands from "./Brands";
import ImgSlider from "./ImgSlider";

import csr1 from "../Components/assets/img/Img/csr/1.webp";
import csr2 from "../Components/assets/img/Img/csr/2.webp";
import csr3 from "../Components/assets/img/Img/csr/3.webp";
import csr4 from "../Components/assets/img/Img/csr/4.webp";
import csr5 from "../Components/assets/img/Img/csr/5.webp";
import csr6 from "../Components/assets/img/Img/csr/6.webp";
import csr7 from "../Components/assets/img/Img/csr/7.webp";
import csr8 from "../Components/assets/img/Img/csr/8.webp";
import csr9 from "../Components/assets/img/Img/csr/9.webp";
import csr11 from "../Components/assets/img/Img/csr/11.webp";
import csr12 from "../Components/assets/img/Img/csr/12.webp";
import cta from "../Components/assets/img/cta.webp";

import aboutus from "../Components/assets/img/about.png";

function Home() {
  return (
    <>
      <Hero />
      {/* About Us */}
      <div className="container-xxl aboutus  my-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7">
              <h1 className="mb-4 heading textprimary">
                <Fade cascade damping={0.1}>
                  Who We Are
                </Fade>
              </h1>

              <Fade cascade damping={1}>
                <p
                  className="mb-4"
                  style={{
                    fontFamily: "Palanquin",
                    color: "#474747",
                    fontSize: "16px",
                    textAlign: "justify",
                    letterSpacing: "0.1px",
                  }}
                >
                  Established in 1990, JDB Group has transformed into a dynamic
                  conglomerate with a diverse portfolio. Initially a supplier of
                  coal and bamboo, we ventured into industrialization in 2003
                  with GG Lime Industry.
                  <br />
                  <br />
                  Our operations span manufacturing LAM-Coke, lime products,
                  food items like wheat flour and snacks, cement, and ethanol.
                  We also excel in services, managing an advanced cold chain, a
                  5-star hotel, trading units, and real estate developments.
                  <br />
                  <br />
                  With around fourteen firms under our umbrella, JDB Group is a
                  multi-dimensional leader in North Eastern India. Committed to
                  societal betterment, we grow our business while enhancing
                  lives.
                  <br />
                  <br />
                  Join us as we continue to innovate and make a positive impact
                  on the communities we serve.
                </p>
              </Fade>
              <Link
                to="/about"
                className="btn btn-primary"
                style={{ backgroundColor: "#44B678", color: "white" }}
              >
                Explore Us
              </Link>
            </div>
            <div class="col-lg-5">
              <img
                style={{ width: "100%", mixBlendMode: "multiply" }}
                src={aboutus}
              />
            </div>
          </div>

          <div id="projectFacts" class="sectionClass">
            <div class="projectFactsWrap row">
              <div
                class="col-lg-4 item wow fadeInUpBig animated animated"
                data-number="12"
                style={{ visibility: "visible" }}
              >
                <i class="fa fa-briefcase"></i>
                <p id="number1" class="number">
                  25+
                </p>
                <span></span>
                <p>Years into Business</p>
              </div>
              <div
                class="col-lg-4 item wow fadeInUpBig animated animated"
                data-number="55"
                style={{ visibility: "visible" }}
              >
                <i class="fa fa-smile-o"></i>
                <p id="number2" class="number">
                  3000+
                </p>
                <span></span>
                <p>Employees</p>
              </div>
              <div
                class="col-lg-4  item wow fadeInUpBig animated animated"
                data-number="359"
                style={{ visibility: "visible" }}
              >
                <i class="fa fa-line-chart"></i>
                <p id="number3" class="number">
                  1000Cr
                </p>
                <span></span>
                <p>Revenue</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Brands */}
      <Brands />

      <ImgSlider />
      <div
        style={{
          background: `url(${cta}) no-repeat center center`,
          backgroundSize: "cover",
          width: "100%",
          height: "30vh",
        }}
      >
        <div
          className="container text-center"
          style={{ color: "white", padding: "50px 10px" }}
        >
          <h2 style={{ fontWeight: "900" }}>Want to Join JDB Group?</h2>
          <p style={{ color: "white" }}>
            Join our esteemed team and contribute to our continued success!
          </p>

          <Link className="btn btn-primary" to="/apply_now">
            Apply Now
          </Link>
        </div>
      </div>
      <br />
      <br />
      <div class="container">
        <div class="csraside">
          <span class="mask"></span>
          <img src="https://img.freepik.com/free-vector/flat-illustrated-csr-concept_23-2148935230.jpg?t=st=1717912928~exp=1717916528~hmac=e47235f90dedf59f72d38c299b3c93878d43ed7f3a2c14051546237109693eb5&w=996" />
          <div class="overlay">
            <img src="https://img.freepik.com/free-vector/flat-illustrated-csr-concept_23-2148935230.jpg?t=st=1717912928~exp=1717916528~hmac=e47235f90dedf59f72d38c299b3c93878d43ed7f3a2c14051546237109693eb5&w=996" />
          </div>
        </div>

        <div class="csrsection">
          <div class="half-top">
            <h1
              className="mb-4 heading textprimary"
              style={{ marginLeft: "60px" }}
            >
              <Fade cascade damping={0.1}>
                CSR Activities
              </Fade>
            </h1>
            <div class="teaser-outer">
              <div class="teaser">
                <ul>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr1} />
                  </li>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr2} />
                  </li>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr3} />
                  </li>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr4} />
                  </li>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr5} />
                  </li>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr6} />
                  </li>
                  <li>
                    <img alt="csr-activities-jdb-group" src={csr7} />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="half-bottom">
            <p>
              JDB Group is deeply committed to social responsibility, actively
              partnering with organizations dedicated to the welfare and
              upliftment of society, especially the weaker sections. Our
              initiatives are designed to address critical areas such as
              poverty, education, healthcare, and social justice. By supporting
              educational programs, we empower individuals with knowledge and
              skills, paving the way for meaningful employment opportunities.
              Our healthcare initiatives aim to improve access to medical
              services and promote overall well-being, ensuring that basic
              health needs are met for marginalized communities.
              <br />
              <br />
              Additionally, JDB Group engages in projects that promote social
              inclusion and equality, working to eliminate barriers that hinder
              disadvantaged groups from achieving their full potential. We
              believe that true progress is measured by the positive impact on
              society, and our continuous efforts reflect this belief. Through
              our dedication to social welfare and the upliftment of the weaker
              sections, we foster a culture of compassion and responsibility,
              contributing to the development of a more equitable and just
              society.
            </p>
            <br />
            <Link
              to="/csr_activities"
              className="btn btn-primary"
              style={{ backgroundColor: "#44B678", color: "white" }}
            >
              Know More
            </Link>
          </div>
        </div>
      </div>
      <br />
      <br />
      {/* Contact US */}
      <Contact />
    </>
  );
}

export default Home;
