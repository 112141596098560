import React from "react";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import "./Style.css"
const Dashboard = () => {
  return (
    <>
      <GoToTop />
      <div class="main-wrapper">
        <div class="sidebar" id="sidebar" style={{ marginTop: "-8px" }}>
          <div class="sidebar-inner slimscroll">
            <div id="sidebar-menu" class="sidebar-menu">
              <ul>
                <li class="menu-title">Main</li>
                <li>
                  <Link to="/portal">
                    <i class="fa fa-dashboard"></i> <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contactMessages">
                    <i class="fa fa-envelope"></i> <span>Messages</span>
                  </Link>
                </li>

                <li>
                  <Link to="/payments">
                    <i class="fa fa-money"></i> <span>Agreements</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page-wrapper">
          <div class="content">
            <div class="row">
              <div class="col-sm-12" style={{ marginTop: "3vh" }}>
                <h4
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  class="page-title"
                >
                  DASHBOARD
                </h4>
              </div>
            </div>
            <div class="row">
              <div className="container-fluid">
                <div className="row" style={{ marginTop: "2vh" }}>
                  <div className="col-lg my-2">
                    <Link to="/boardview"></Link>
                  </div>

                  <div className="col-lg my-2">
                    <Link to="/mentorsview"></Link>
                  </div>

                  <div className="col-lg my-2">
                    <Link to="/teammembers"></Link>
                  </div>

                  {/* <div className="col-lg my-2">
                    <Link to="/blogslist">
                      <Card
                        image={blog}
                        header="Blogs"
                        description="Details >>"
                      />
                    </Link>
                  </div> */}

                  <div className="col-lg my-2">
                    <Link to="/contactMessages"></Link>
                  </div>

                  <div className="col-lg my-2">
                    <Link to="/payments"></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
