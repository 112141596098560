import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import img1 from "./assets/img/Img/Novotel/cover_img.webp";
import img2 from "./assets/img/Img/other/food_processing.webp";
import img3 from "./assets/img/Img/other/Raksha1.jpg";
import img4 from "./assets/img/Img/other/aadhargreen.webp";
import { Link } from "react-router-dom";
function Brands() {
  return (
    <>
      <main class="container">
        <div className="section-title">
          <h1
            className="heading textprimary text-center"
            style={{ fontWeight: "700" }}
          >
            <Fade cascade damping={0.1}>
              Our Ventures
            </Fade>
          </h1>
        </div>
        {/* <div class="row">
          <div className="col-lg-3">
            <div class="card ">
              <div class="card__img">
                <div class="img__cover">
                  <img src={img1} alt="novotel guwahati jdpgroup" />
                </div>
              </div>
              <div class="[ card__text ] [ flow ]">
                <h2>
                  <Link to="/novotel">Novotel Guwahati</Link>
                </h2>

                <p>
                  Featuring a host of 5-star amenities, "Novotel Guwahati" sets
                  the gateway of European hospitality to North East India. The
                  hotel presents 118 sophisticatedly designed rooms and suites,
                  4 meeting spaces with modern and luxurious amenities...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div class="card ">
              <div class="card__img">
                <div class="img__cover">
                  <img src={img2} alt="" />
                </div>
              </div>
              <div class="[ card__text ] [ flow ]">
                <h2>
                  <Link to="/food_proccessing">
                    JDB Foods and JDB Steel LLP
                  </Link>
                </h2>

                <p>
                  JDB GROUP has set up a state-of-the-art food processing unit
                  at the Industrial Growth Centre, Chatabari, Chaygaon, Kamrup,
                  Assam, manufacturing "Kurkure" and "Uncle Chipps." This unique
                  facility in the NER serves as a co-packer for PepsiCo India
                  Holding (P) Limited...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div class="card">
              <div class="card__img">
                <div class="img__cover">
                  <img src={img3} alt="" />
                </div>
              </div>
              <div class="[ card__text ] [ flow ]">
                <h2>
                  <Link to="/raksha">Raksha Cements Pvt Ltd</Link>
                </h2>

                <p>
                  RAKSHA Cements Pvt. Ltd, the North East's leading manufacturer
                  of 43-grade Ordinary Portland Cement and Portland Pozzolana
                  Cement, boasts a comprehensive network of factories and
                  marketing offices across the region. Renowned for its RAKSHA
                  brand...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div class="card">
              <div class="card__img">
                <div class="img__cover">
                  <img src={img4} alt="" />
                </div>
              </div>
              <div class="[ card__text ] [ flow ]">
                <h2>
                  <Link to="/aadhar_industries">Aadhar Industries LLP</Link>
                </h2>

                <p>
                  Aadhar Industries with its modern infrastructures entirely as
                  per GERMAN TECHNOLOGY, has started producing OPC (Ordinary
                  Portland Cement) & PPC (Portland Pozzolana Cement) -
                  conforming to BIS CODE under the brand name of' 'Holltech'...
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <section class="section-5 my-4">
          <div class="row">
            <div className="col-lg-3">
              <Link to="/novotel">
                <figure class="figure">
                  <img src={img1} alt="" />
                  <figcaption>
                    <div class="icon">
                      <span>
                        <ion-icon name="images"></ion-icon>
                      </span>
                    </div>
                    <h2>Novotel Guwahati</h2>
                    <div class="caption">
                      <p>View Details</p>
                    </div>
                  </figcaption>
                  <a href="#"></a>
                </figure>
              </Link>
            </div>
            <div className="col-lg-3">
              <Link to="/food_proccessing">
                <figure class="figure">
                  <img src={img2} alt="" />
                  <figcaption>
                    <div class="icon">
                      <span>
                        <ion-icon name="images"></ion-icon>
                      </span>
                    </div>
                    <h2>JDB Foods & JDB Steel LLP</h2>
                    <div class="caption">
                      <p>View Details</p>
                    </div>
                  </figcaption>
                  <a href="#"></a>
                </figure>
              </Link>
            </div>
            <div className="col-lg-3">
              <Link to="/raksha">
                <figure class="figure">
                  <img src={img3} alt="" />
                  <figcaption>
                    <div class="icon">
                      <span>
                        <ion-icon name="images"></ion-icon>
                      </span>
                    </div>
                    <h2>Raksha Cements Pvt Ltd</h2>
                    <div class="caption">
                      <p>View Details</p>
                    </div>
                  </figcaption>
                  <a href="#"></a>
                </figure>
              </Link>
            </div>
            <div className="col-lg-3">
              <Link to="/aadhar_industries">
                <figure class="figure">
                  <img src={img4} alt="" />
                  <figcaption>
                    <div class="icon">
                      <span>
                        <ion-icon name="images"></ion-icon>
                      </span>
                    </div>
                    <h2>Aadhar Industries LLP</h2>
                    <div class="caption">
                      <p>View Details</p>
                    </div>
                  </figcaption>
                </figure>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Brands;
