import React from "react";
import bg from "../Components/assets/img/csractivities.webp";

import csr1 from "../Components/assets/img/Img/csr/1.webp";
import csr2 from "../Components/assets/img/Img/csr/2.webp";
import csr3 from "../Components/assets/img/Img/csr/3.webp";
import csr4 from "../Components/assets/img/Img/csr/4.webp";
import csr5 from "../Components/assets/img/Img/csr/5.webp";
import csr6 from "../Components/assets/img/Img/csr/6.webp";
import csr7 from "../Components/assets/img/Img/csr/7.webp";
import csr8 from "../Components/assets/img/Img/csr/8.webp";
import csr9 from "../Components/assets/img/Img/csr/9.webp";
import csr11 from "../Components/assets/img/Img/csr/11.webp";
import csr12 from "../Components/assets/img/Img/csr/12.webp";
import csr13 from "../Components/assets/img/Img/csr/13.webp";
import csr14 from "../Components/assets/img/Img/csr/14.webp";
import csr15 from "../Components/assets/img/Img/csr/15.webp";
import csr16 from "../Components/assets/img/Img/csr/16.webp";
import csr17 from "../Components/assets/img/Img/csr/17.webp";
import csr18 from "../Components/assets/img/Img/csr/18.webp";
import csr19 from "../Components/assets/img/Img/csr/19.webp";
import GoToTop from "../GoToTop";
function CSRactivities() {
  return (
    <>
      <GoToTop />
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={bg} class="d-block w-100 hero_img_title" alt="..." />
            <div class="carousel-caption d-md-block">
              <h3 className="headerHeadTitle">CSR Activities</h3>
              <h5 className="headerHeadPara">
                Empowering communities through sustainable initiatives and
                impactful projects, fostering growth and positive change for a
                brighter future together.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="card">
            <div className="card-body">
              <h6>
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>{" "}
                Specialised Team from Renowned health service providers
                providing their health checkup services to the permanent and
                casual workers at our plant- JDB Steel LLP. (Taking care of
                employees well being)
              </h6>

              <hr />
              <div className="row">
                <div className="col-lg-3 my-2">
                  <img
                    src={csr4}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr3}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div className="col-lg-3 my-2">
                  <img
                    src={csr5}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2"></div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>
                <i class="fa fa-angle-double-right" aria-hidden="true"></i> A
                token of expressing gratitude and spreading harmony amongst the
                local community in the vicinity by gifting confectionery, chips
                etc to the school kids of the area. (JDB steel LLP & JDB foods)
              </h6>
              <hr />
              <div className="row">
                <div className="col-lg-3 my-2">
                  <img
                    src={csr6}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr7}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr9}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div className="col-lg-3 my-2"></div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h6>
                <i class="fa fa-angle-double-right" aria-hidden="true"></i> Free
                eye checkup ophthalmology camp to spread awareness amongst the
                workers regarding eye diseases. (JDB Steel LLP)
              </h6>
              <hr />
              <div className="row">
                <div className="col-lg-3 my-2">
                  <img
                    src={csr1}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr2}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr8}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div className="col-lg-3 my-2"></div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h6>
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>{" "}
                Keeping up the idea and moto of "Swacchata param kartavyam" and
                aligning the objective towards Clean India,
                <br />A team of JDB organising a community sanitation and
                cleanliness programme to spread awareness amongst the local
                population of some area in Guwahati.
              </h6>
              <hr />
              <div className="row">
                <div className="col-lg-3 my-2">
                  <img
                    src={csr17}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr18}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr19}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div className="col-lg-3 my-2">
                  <img
                    src={csr16}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h6>
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>{" "}
                Undertaking the responsibility of CSR and forebearers of the
                societal change and awareness. A Team of JDB distributing
                ceiling fans in Govt school of the Vicinity.
              </h6>
              <hr />
              <div className="row">
                <div className="col-lg-3 my-2">
                  <img
                    src={csr11}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr12}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-lg-3 my-2">
                  <img
                    src={csr13}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div className="col-lg-3 my-2">
                  <img
                    src={csr14}
                    alt=""
                    style={{
                      width: "100%",
                      height: "190px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /> <br /> <br />
    </>
  );
}

export default CSRactivities;
