import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const data = {
    labels: ["2014-15", "2017-18", "2021-22", "2024-25"],
    datasets: [
      {
        label: "Turn Over",
        data: [313, 360, 550, 1000],
        backgroundColor: "#e3505267",
        borderColor: "#e35052",
        borderWidth: 1,
      },
      {
        label: "Employee",
        data: [1200, 1700, 2200, 3500],
        backgroundColor: "rgba(204, 255, 196, 0.7)",
        borderColor: "#5fd94c",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
