import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import img1 from "../assets/img/Img/other/aadhar2.webp";
import img2 from "../assets/img/Img/other/aadhar1.webp";
import img3 from "../assets/img/Img/other/aadhar3.webp";
import img4 from "../assets/img/Img/other/aadhar4.webp";
import aadhar_cover from "../assets/img/aadharind_cover_page.webp";
import raksha_covermb from "../assets/img/aadharind_cover_page.webp";
import GoToTop from "../../GoToTop";

function AadharInd() {
  return (
    <>
      <GoToTop />
      <img
        src={aadhar_cover}
        id="pccover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <img
        src={raksha_covermb}
        id="mbcover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <div class="container-xxl aboutus my-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <Slide>
                <h1 className="mb-4 heading textprimary">
                  Aadhar Industries LLP
                </h1>
              </Slide>
              <Fade cascade damping={1}>
                <p class="mb-4">
                  Aadhar Industries with its modern infrastructures entirely as
                  per GERMAN TECHNOLOGY, has started producing OPC (Ordinary
                  Portland Cement) & PPC (Portland Pozzolana Cement) -
                  conforming to BIS CODE under the brand name of' 'Holltech'.
                  Our plant is situated at Bamungaon - Lanka under Nagaon
                  district of Assam.
                  <br />
                  <br />
                  Aadhar Industries is a cutting-edge cement manufacturing unit
                  with an ambition to establish itself as a leading player in
                  the North Eastern India. Enriched with the heritage of Raksha
                  Cements & Bulland Cement of JDB GROUP, a leading name in the
                  multifarious Trading, our corporate culture is built on the
                  values of quality, trust and transparency. An emphasis on
                  technological superiority enables us to differentiate our
                  offering through impeccable quality and effective
                  communication. With an extensive network of Promoters cum
                  Dealers, we endeavour to earn a place in the premium segment
                  of the market in this region. Aadhar Industries has ambitious
                  plans to further expand its current capacity to produce more
                  volume by setting up additional production line for which
                  infra-structures are ready.
                </p>
              </Fade>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={img1}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={img2}
                  />
                </div>
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-70 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={img3}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={img4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
    </>
  );
}

export default AadharInd;
