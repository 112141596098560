import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import img1 from "../assets/img/Img/other/steel1.webp";
import img2 from "../assets/img/Img/other/steel2.webp";
import img3 from "../assets/img/Img/other/steel3.webp";
import img4 from "../assets/img/Img/other/steel4.webp";
import steelInd_cover from "../assets/img/steelIndustry_cover_page.webp";
import steelInd_covermb from "../assets/img/steelIndustry_cover_page.webp";
import GoToTop from "../../GoToTop";

function SteelIndustry() {
  return (
    <>
      <GoToTop />
      <img
        src={steelInd_cover}
        id="pccover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <img
        src={steelInd_covermb}
        id="mbcover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <div class="container-xxl aboutus my-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <Slide>
                <h1 className="mb-4 heading textprimary">HëRRlích Alûmin</h1>
              </Slide>
              <Fade cascade damping={1}>
                <p class="mb-4">
                  HëRRlích Alûmin only uses the high quality virgin ingot
                  procured from HINDALCO and NALCO to produce the superior
                  quality product with luxury finish and High Temper finished
                  products.
                  <br />
                  <br />
                  Our Press is equipped with an automatic gauge control system
                  (AGC) to maintain the output thickness as per required
                  specifications. We have also installed an X-ray gauge checking
                  system by Mesacon– Germany and an Automatic Flatness
                  Controller system (AFC) from Achenbach, Germany due to which
                  the products exceed any international standards required by
                  the most demanding applications.
                  <br />
                  <br />
                  With an installed production capacity of 300MT per month,
                  HëRRlích Alûmin is one of the largest integrated aluminium
                  extrusion manufacturers in the North East India. Our state of
                  art manufacturing facility includes cutting edge German
                  Aluminium Extrusion Press of 1250 UST capacity, a billet
                  casting facility (Hot Top casting) imported from Germany,
                  Latest Anodising Plant and Robotic Powder Coating Machinery
                  from Taiwan. We also have a modern tool shop equipped with the
                  latest CNC, EDM wire cut and other ancillary equipments.
                </p>
              </Fade>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={img1}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={img2}
                  />
                </div>
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-70 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={img3}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={img4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
    </>
  );
}

export default SteelIndustry;
