import React, { useState } from "react";
import { Link } from "react-router-dom";
import insta from "./assets/img/Logos/insta.svg";
import fb from "./assets/img/Logos/fb.svg";
import linkedin from "./assets/img/Logos/linkedin.svg";
import twitter from "./assets/img/Logos/twitter.svg";
import { db } from "../Firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Swal from "sweetalert2";
function Footer() {
  const generateUniqueId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uniqueId = generateUniqueId();

    try {
      const docRef = await addDoc(collection(db, "newsletters"), {
        ...formData,
        createdAt: serverTimestamp(),
        uniqueId: uniqueId,
      });
      // console.log("Document written with ID: ", docRef.id);
      Swal.fire({
        title: "Success! You are now subscribed",
        icon: "success",
      });

      setFormData({ email: "" });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 footer-contact">
                <h3>JDB Group</h3>
                <p>
                  29(B), Gaurav Bhawan,
                  <br /> 3rd Floor, D. Neog Path, G S Road, ABC Guwahati, Pin -
                  781005,
                  <br />
                  Assam, India <br />
                  <br />
                  <strong>Phone:</strong> 0361-2465182
                  <br />
                  <strong>Email:</strong> info@jdbgrp.com
                  <br />
                </p>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Resources</h4>
                <ul>
                  <li>
                    <Link href="">Home</Link>
                  </li>
                  <li>
                    <Link href="">Partner with us</Link>
                  </li>
                  <li>
                    <Link href="">About JDB Group</Link>
                  </li>
                  <li>
                    <Link href="">Customer Service</Link>
                  </li>
                  <li>
                    <Link href="">Terms of service</Link>
                  </li>
                  <li>
                    <Link href="">Privacy policy</Link>
                  </li>
                </ul>
              </div>

              <div class="col-lg-5 col-md-6 footer-newsletter">
                <h4>Subscribe to our newsletter</h4>
                <p>
                  Get exclusive updates. Access our official brochure for more
                  info
                </p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <button style={{ width: "20vh" }} type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="container d-md-flex py-4">
          <div class="me-md-auto text-center text-md-start">
            <div class="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>JDB Group</span>
              </strong>
              . All Rights Reserved
            </div>
            {/* <div class="credits">
              Designed by <Link href="https://bootstrapmade.com/">BootstrapMade</Link>
            </div> */}
          </div>
          <div class="social-links text-center text-md-end pt-3 pt-md-0">
            <Link href="" class="twitter">
              <img src={insta} alt="insta" style={{ width: "20px" }} />
            </Link>
            <Link href="" class="facebook">
              <img src={fb} alt="insta" style={{ width: "20px" }} />
            </Link>
            <Link href="" class="instagram">
              <img src={twitter} alt="insta" style={{ width: "20px" }} />
            </Link>

            <Link href="" class="linkedin">
              <img src={linkedin} alt="insta" style={{ width: "20px" }} />
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
