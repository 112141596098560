import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCJsCUeUNg1_CBKK7Bbi76wMMXwlzwufbc",
  authDomain: "jdbgroup-50e67.firebaseapp.com",
  projectId: "jdbgroup-50e67",
  storageBucket: "jdbgroup-50e67.appspot.com",
  messagingSenderId: "289060189870",
  appId: "1:289060189870:web:9aee6cf5e8e7e29f7f5f3f",
  measurementId: "G-N4HBB5KMR1",
});

// Initialize Firebase
const db = firebaseApp.firestore();
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, storage };
