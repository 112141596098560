import React from "react";
import { Link } from "react-router-dom";
function Page404() {
  return (
    <>
      <div className="display">
        <div className="display__img">
          <img
            style={{ mixBlendMode: "multiply" }}
            src="https://cdn.vectorstock.com/i/500p/65/25/web-online-page-with-404-not-found-error-vector-27386525.jpg"
            alt="404-Scarecrow"
          />
        </div>
        <div className="display__content">
          <h2 className="display__content--info">I have bad news for you</h2>
          <p className="display__content--text">
            The page you are looking for might be removed or is temporarily
            unavailable
          </p>
          <Link to="/" className="btn btn-primary">
            Back to homepage
          </Link>
        </div>
      </div>
    </>
  );
}

export default Page404;
