import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import img1 from "../assets/img/Img/other/food2.webp";
import img2 from "../assets/img/Img/other/food1.webp";
import img3 from "../assets/img/Img/other/food_processing.webp";
import img4 from "../assets/img/Img/other/food3.webp";
import raksha_cover from "../assets/img/jdbfood_steel.webp";
import raksha_covermb from "../assets/img/raksha_cover_mb.webp";
import GoToTop from "../../GoToTop";

function FoodProccessing() {
  return (
    <>
      <GoToTop />
      <img
        src={raksha_cover}
        id="pccover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <img
        src={raksha_covermb}
        id="mbcover"
        style={{ width: "100%", marginTop: "10vh" }}
      />
      <div class="container-xxl aboutus my-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <Slide>
                <h1 className="mb-4 heading textprimary">
                  JDB Foods and JDB Steel LLP
                </h1>
              </Slide>
              <Fade cascade damping={1}>
                <p class="mb-4">
                  A manufacturing unit of 'JDB GROUP', set up as a Food
                  Processing Unit for manufacturing of snack products under the
                  brand names of "Kurkure" and "Uncle Chipps" at the Industrial
                  Growth Centre, Chatabari, Chaygaon, Kamrup, Assam as a co
                  packer of M/s PepsiCo India Holding (P) Limited, Gurgaon,
                  Haryana. It is a state of the art unit which is one of a kind
                  in the NER, and caters to the demand of entire NER markets for
                  PepsiCo and parts of West Bengal as well. The unit is equipped
                  with modern machineries imported from the best in the world
                  and is one of the biggest in the region in snacks
                  manufacturing. The unit is rated as one of the best units for
                  PepsiCo nationwide. The unit maintains the highest possible
                  levels of quality standards adhering to all applicable food
                  safety norms setting a standard for others.
                </p>
              </Fade>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={img1}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={img2}
                  />
                </div>
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-70 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={img3}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-80 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={img4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
    </>
  );
}

export default FoodProccessing;
