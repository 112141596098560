import React from "react";
import { Fade } from "react-awesome-reveal";
import GoToTop from "../GoToTop";
import team1 from "../Components/assets/img/team/1.webp";
import team2 from "../Components/assets/img/team/2.webp";
import team3 from "../Components/assets/img/team/3.webp";

import teambg from "../Components/assets/img/team.webp";
function Team() {
  return (
    <>
      <GoToTop />
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={teambg} class="d-block w-100 hero_img_title" alt="..." />
            <div class="carousel-caption d-md-block">
              <h3 className="headerHeadTitle">Our Team</h3>
              <h5 className="headerHeadPara">
                United in purpose, diverse in talents—our team drives innovation
                and achieves excellence together.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="container-xxl aboutus" style={{ marginTop: "5vh" }}>
        <div className="container">
          {/* <div className="section-title">
            <h1
              className="mb-4 heading textprimary text-center"
              style={{ fontWeight: "700" }}
            >
              <Fade cascade damping={0.1}>
                Our Team
              </Fade>
            </h1>
          </div> */}

          <div className="card">
            <div
              className="card-header"
              style={{ backgroundColor: "#2C2D2D", color: "white" }}
            >
              <h4>Mr. Shiwaji Pd. Jaiswal</h4>
              <span>CMD, JDB Group</span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9" style={{ textAlign: "justify" }}>
                  <p>
                    Mr. Shiwaji Pd. Jaiswal, son of Mr. Rajendra Prasad Shah, is
                    a resident of G.S. Road, Jorabat, Kamrup, Assam. Aged around
                    45 years and equipped with wide range of business qualities,
                    Mr. Jaiswal is dynamic and very enterprising person and has
                    acquired in-depth knowledge coupled with experience as
                    regard to various pros-&-cons in business & industrial
                    areas. He is a visionary and possesses an astounding degree
                    of foresight which helps him overcome one hurdle over
                    another and keep achieving substantial milestones with an
                    astronomical pace. As a leader of the group, he has been
                    immensely successful in steering the GROUP through the ups
                    and downs of the business cycle.
                    <br /> <br /> Mr.Jaiswal has always been able to ensure
                    developing appropriate short, medium and long term
                    strategies not only to protect market share but also to
                    grow-thereby keeping the GROUP prominently active in a wide
                    range of industries including as the Manufacturer (of
                    LAM-Coke, Lime & Lime products, Food Products- wheat flour &
                    products, Cements, Snacks & Plywood) along with Cold Chain /
                    Cold Storage unit, trading- FMCG, Hotel - Hospitality
                    Industries & Real Estate. With about Fourteen (14) numbers
                    of Firms under the spacious umbrella of JDB GROUP, the GROUP
                    has evolved as a multi-dimensional and diversified business
                    entity in the North Eastern India. <br />
                    <br />
                    As the CMD of JDB GROUP, he is the one that's In-Charge of
                    the whole GROUP, from the day to day routine Operations to
                    big picture problems.
                  </p>
                </div>
                <div className="col-lg-3">
                  <img
                    style={{ width: "100%" }}
                    src={team1}
                    alt="Shiwaji Pd. Jaiswal"
                  />
                </div>
              </div>

              <div className="row" style={{ textAlign: "justify" }}>
                <p>
                  He is instrumental in:
                  <ul style={{ marginLeft: "10px" }}>
                    <li>Developing strategic plans & controlling finance</li>
                    <li>Successfully implementing company policies</li>
                    <li>
                      Maintaining a dialogue between shareholders and the board
                    </li>
                    <li>
                      Building and maintaining an effective management team
                    </li>
                    <li>
                      Assuming full accountability for the board for all
                      operations of the GROUP
                    </li>
                    <li>Successfully implementing company policy</li>
                  </ul>
                </p>

                <p>
                  Mr. Shiwaji Pd. Jaiswal, possesses strong business and
                  leadership skills as Chairman Cum Managing Director (CMD) of
                  JDB GROUP and has a deep understanding of the consumer and
                  business landscape not only in the region but in the country
                  and even beyond. He is actively associated with various
                  social, culture and sports organizations. He is gifted with a
                  futuristic vision and knows how to turn ideas into real-world
                  success stories. Mr. Jaiswal is one of the most recognized
                  remarkable business leaders in the North Eastern Region, and
                  through his strength, innovation and foresight, he has
                  successfully induced positive changes in every person around
                  him. He is a leader who knows the way, shows the way and goes
                  the way.
                </p>
              </div>
            </div>
          </div>

          <div className="card">
            <div
              className="card-header"
              style={{ backgroundColor: "#2C2D2D", color: "white" }}
            >
              <h4>Mr. Ganesh Sarma</h4>
              <span>Director, JDB Group</span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    style={{ width: "100%" }}
                    src={team2}
                    alt="Ganesh Sarma"
                  />
                </div>
                <div className="col-lg-9" style={{ textAlign: "justify" }}>
                  <p>
                    Mr. Ganesh Sarma, son of Late Nandalal Sarma has an
                    extensive range of experience including capture of new
                    ventures and Group's business turn- around apart from
                    marketing cum general management of all related Firms under
                    the Group at all levels. Aged around 46 years, Mr. Sarma is
                    a resident of G.S. Road, Jorabat, Kamrup, Assam.
                    <br /> <br />
                    Being one of the senior most Directors of JDB GROUP, Mr.
                    Sarma is a dynamic and enterprising entrepreneur and is well
                    equipped with in depth knowledge in most products like Coke,
                    Lime, cements and Plywood and food products etc apart from
                    marketing. Also He is always well aware of various statutory
                    requirements by related Departments of both Central & State
                    governments and it's time to time amendment thereby ensuring
                    effective implementation in various fields of the Group
                    never leaving any lacuna.
                    <br />
                    <br />
                    Mr.Ganesh Sarma is a smart and sober person by nature and
                    comes with immense networking capabilities and has been
                    instrumental in laying the foundation of JDB GROUP from the
                    time of the very inception. Further, Mr. Sarma is associated
                    with many religious, socio-cultural and sports organiza-
                    tions and has been successfully and prominently involved in
                    many business activities since last 25 years.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div
              className="card-header"
              style={{ backgroundColor: "#2C2D2D", color: "white" }}
            >
              <h4>Mr. Madan Sha</h4>
              <span>Director, JDB Group</span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9" style={{ textAlign: "justify" }}>
                  <p>
                    Mr. Madan Sha, son of Shri Laxman Sha, is a resident of
                    Nabarun Path, Jatia, Guwahati, Assam. Aged around 44 years,
                    Mr. Madan Sha is one of the founder Directors of 'JDB GROUP'
                    right from its inception and is a very enterprising business
                    person-acquiring high degree of professionalism. Mr. Madan
                    Sha has been successfully leading and conceptualizing
                    multiple business opportunities for JDB GROUP and has been
                    able to efficiently monitoring operations and productions.
                    Also he has been the pillar of the GROUP in laying the
                    foundation for the Real estate business.
                    <br />
                    <br />
                    He has about two decades of experience in various fields
                    including the real estate sector and is fully equipped with
                    the architectural, construction skills and has also been
                    catering purchase departments of the GROUP. Being socially
                    responsible corporate personnel, Mr. Sha has been well known
                    for his leadership quality in various fields of the GROUP.
                    Being honest and sober by nature, He has been closely
                    associated with day to day activities in the operational and
                    commercial management departments of the GROUP and it is by
                    virtue of his close association and able guidelines that all
                    the concerns of JDB GROUP today have come up to forefront.
                  </p>
                </div>
                <div className="col-lg-3">
                  <img style={{ width: "100%" }} src={team3} alt="Madan Sha" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
